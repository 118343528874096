// extracted by mini-css-extract-plugin
export var address = "contact-module--address--3fn7K";
export var contact = "contact-module--contact--yUVT2";
export var contactBox = "contact-module--contact-box---gBnt";
export var contactForm = "contact-module--contact-form--s1m0Y";
export var contactLeftWrapper = "contact-module--contact-left-wrapper--pyi8E";
export var contactRightWrapper = "contact-module--contact-right-wrapper--TBYVZ";
export var container = "contact-module--container--DCZVC";
export var heading = "contact-module--heading--KPYqx";
export var portrait = "contact-module--portrait--IKm7Z";
export var subtitle = "contact-module--subtitle--iPyvn";
export var validate = "contact-module--validate--129lI";
export var wrapper = "contact-module--wrapper--GUCpN";